<template>
  <div>
  <AppBar :title="$t('editShoppingCenter.title')"/>
  <v-progress-linear v-if="loading" color="blue" class="progress" indeterminate></v-progress-linear>
  <v-card flat v-if="shop" class="rounded-xl pt-2 pb-4">
    <v-card-text>
      <v-form ref="addForm" v-model="valid">
        <v-row>
          <v-col cols="12" md="5">
            <h4>{{ $t('editShoppingCenter.shopData') }}</h4>
            <v-text-field v-model="shop.name" :label="$t('editShoppingCenter.name')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.company" :label="$t('editShoppingCenter.company')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.city" :label="$t('editShoppingCenter.city')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.subdomain" :label="$t('editShoppingCenter.subdomain')" disabled></v-text-field>
            <v-text-field v-model="shop.email" :label="$t('editShoppingCenter.email')" :rules="emailRules"></v-text-field>
            <h4>{{ $t('editShoppingCenter.pickupMethods') }}</h4>
            <v-checkbox v-model="shop.allowShippment" :label="$t('editShoppingCenter.shipping')"></v-checkbox>
            <v-checkbox v-model="shop.allowSelfPickup" :label="$t('editShoppingCenter.selfPickup')"></v-checkbox>
            <v-checkbox v-model="shop.allowClickAndCollect" :label="$t('editShoppingCenter.clickAndCollect')"></v-checkbox>
            <h4 class="mt-4">{{ $t('editShoppingCenter.changeGraphics') }}</h4>
            <v-file-input v-model="backgroundImage" :label="$t('editShoppingCenter.background')" prepend-icon="mdi-camera" color="secondary" accept="image/*"></v-file-input>
            <v-file-input v-model="logo" :label="$t('editShoppingCenter.logo')" prepend-icon="mdi-camera" color="secondary" accept="image/*"></v-file-input>
            <v-file-input v-model="darkModeLogo" :label="$t('editShoppingCenter.darkModeLogo')" prepend-icon="mdi-camera" color="secondary" accept="image/*"></v-file-input>
            <v-file-input v-model="giftCardImage" :label="$t('editShoppingCenter.giftCardImage')" prepend-icon="mdi-camera" color="secondary" accept="image/*"></v-file-input>
          </v-col>
          <v-col cols="12" md="5" offset-md="1">
              <h4 class="mb-4">{{ $t('editShoppingCenter.previewGraphics') }}</h4>
              <h5>{{ $t('editShoppingCenter.logo') }}</h5>
              <img class="my-2" height="50" :src="url+shop.logo"/>
            <div v-if="shop.darkModeLogo">   <h5>{{ $t('editShoppingCenter.darkModeLogo') }}</h5>
              <img class="my-2" height="50" :src="url+shop.darkModeLogo"/>
            </div>
            <h5>{{ $t('editShoppingCenter.background') }}</h5>
            <img class="my-2" height="150" :src="url+shop.backgroundImage"/>
            <div v-if="shop.giftCardImage">
              <h5>{{ $t('editShoppingCenter.giftCardImage') }}</h5>
              <img class="my-2" height="150" :src="url+shop.giftCardImage"/>
            </div>
          </v-col>
          <v-col cols="12" md="5">
            <h4>{{ $t('editShoppingCenter.menuColor') }}</h4>
            <v-row>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.menuColor') }}</h5>
                    <v-sheet dark :color="shop.menuBackgroundColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.menuBackgroundColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.menuBackgroundColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.menuTextColor') }}</h5>
                    <v-sheet dark :color="shop.menuFontColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.menuFontColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.menuFontColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.headerTextColor') }}</h5>
                    <v-sheet dark :color="shop.headerFontColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.headerFontColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.headerFontColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.cardsBackgroundColor') }}</h5>
                    <v-sheet dark :color="shop.cardsBackgroundColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.cardsBackgroundColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.cardsBackgroundColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x >
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.cardsIconColor') }}</h5>
                    <v-sheet dark :color="shop.cardsIconColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.cardsIconColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.cardsIconColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.cardsTextColor') }}</h5>
                    <v-sheet dark :color="shop.cardsFontColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.cardsFontColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.cardsFontColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.cardsButtonColor') }}</h5>
                    <v-sheet dark :color="shop.cardsButtonColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.cardsButtonColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.cardsButtonColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.cardsButtonTextColor') }}</h5>
                    <v-sheet dark :color="shop.cardsButtonFontColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.cardsButtonFontColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.cardsButtonFontColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>{{ $t('editShoppingCenter.footerTextColor') }}</h5>
                    <v-sheet dark :color="shop.footerFontColor" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.footerFontColor }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.footerFontColor" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="5" offset-md="1">
            <v-text-field v-model="shop.headerFirstLineText" :label="$t('editShoppingCenter.header')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.headerLastLineText" :label="$t('editShoppingCenter.headerSecondLine')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.footerText" :label="$t('editShoppingCenter.footer')" :rules="required"></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="12" md="6">
          <v-btn depressed color="secondary" :loading="loading" :disabled="loading" @click="save" rounded>{{ $t('editShoppingCenter.save') }}</v-btn>
          <v-btn depressed class="ml-4" @click="$router.push({ name: 'ShoppingCenters' })" :disabled="loading" rounded>{{ $t('editShoppingCenter.close') }}</v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
  </div>
</template>
<script>
import Service from '@/services'
export default {
  name: 'EditShoppingCenter',
  data () {
    return {
      menu: false,
      valid: true,
      loading: false,
      shop: null,
      backgroundImage: null,
      logo: null,
      darkModeLogo: null,
      giftCardImage: null,
      required: [(v) => !!v || this.$t('validation.fieldRequired')],
      emailRules: [
        v => !!v || this.$t('validation.fieldRequired'),
        v => /.+@.+\..+/.test(v) || this.$t('validation.incorrectEmailAddress')
      ]
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getShoppingCenter()
    }
  },
  computed: {
    url () {
      return process.env.VUE_APP_BASE + 'uploads/'
    }
  },
  methods: {
    async getShoppingCenter () {
      this.loading = true

      const { data } = await Service.get(`/ShoppingCenters/${this.$route.params.id}/Management`)
      this.shop = data

      this.loading = false
    },
    async save () {
      this.$refs.addForm.validate()

      if (!this.valid) return
      this.loading = true
      const countryCode = localStorage.getItem('tenantValue')

      const formData = new FormData()
      formData.append('Data',
        JSON.stringify({
          Name: this.shop.name,
          Company: this.shop.company,
          City: this.shop.city,
          Email: this.shop.email,
          MenuBackgroundColor: this.shop.menuBackgroundColor,
          MenuFontColor: this.shop.menuFontColor,
          HeaderFirstLineText: this.shop.headerFirstLineText,
          HeaderFontColor: this.shop.headerFontColor,
          HeaderLastLineText: this.shop.headerLastLineText,
          CardsBackgroundColor: this.shop.cardsBackgroundColor,
          CardsIconColor: this.shop.cardsIconColor,
          CardsFontColor: this.shop.cardsFontColor,
          CardsButtonColor: this.shop.cardsButtonColor,
          CardsButtonFontColor: this.shop.cardsButtonFontColor,
          FooterFontColor: this.shop.footerFontColor,
          FooterText: this.shop.footerText,
          AllowShippment: this.shop.allowShippment,
          AllowSelfPickup: this.shop.allowSelfPickup,
          AllowClickAndCollect: this.shop.allowClickAndCollect,
          Tenant: countryCode
        })
      )

      if (this.logo) {
        formData.append('Logo', this.logo)
      }

      if (this.darkModeLogo) {
        formData.append('DarkModeLogo', this.darkModeLogo)
      }

      if (this.backgroundImage) {
        formData.append('BackgroundImage', this.backgroundImage)
      }

      if (this.giftCardImage) {
        formData.append('GiftCardImage', this.giftCardImage)
      }

      try {
        await Service.put(`/ShoppingCenters/${this.shop.id}`, formData)

        this.$refs.addForm.reset()
        this.$router.push({ name: 'ShoppingCenters' })
      } catch (error) {}

      this.loading = false
    }
  }
}
</script>
