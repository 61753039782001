<template>
  <v-dialog v-model="dialog" :max-width="options.width" :style="{ zIndex: options.zIndex }" @keydown.esc="cancel" persistent content-class="rounded-xl">
    <v-card class="rounded-xl pt-2 pb-4">
        <v-card-title class="text-h5">{{ title }}</v-card-title>
        <v-card-text>
          <v-form ref="addForm" v-model="valid">
            <v-file-input v-model="slideImage" :label="$t('editShoppingCenter.addPhoto')" prepend-icon="mdi-camera" color="secondary" accept="image/*" :rules="required"></v-file-input>
          </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click.native="save" :disabled="loading || !valid" :loading="loading" color="secondary" depressed> {{$t('editShoppingCenter.save')}} </v-btn>
            <v-btn @click.native="cancel" :disabled="loading" depressed> {{$t('resetPassword.cancel')}} </v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import Service from '@/services'
export default {
  data: () => ({
    loading: false,
    dialog: false,
    valid: false,
    slideId: null,
    shopId: null,
    title: null,
    slideImage: null,
    options: {
      color: 'primary',
      width: 350,
      zIndex: 200
    },
    required: [(v) => !!v || this.$t('validation.fieldRequired')]
  }),
  methods: {
    open (title, slideId, shopId) {
      this.dialog = true
      this.title = title
      this.slideId = slideId
      this.shopId = shopId
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async save () {
      this.$refs.addForm.validate()

      if (!this.valid) return
      this.loading = true

      const formData = new FormData()

      try {
        if (this.slideId) {
          formData.append('Data', JSON.stringify({ Id: this.slideId }))

          if (this.slideImage) {
            formData.append('Image', this.slideImage)
          }

          await Service.put(`/CarouselSlides/${this.slideId}`, formData)
        } else {
          formData.append('Data', JSON.stringify({ ShoppingCenterId: this.shopId }))

          if (this.slideImage) {
            formData.append('Image', this.slideImage)
          }

          await Service.post(`/CarouselSlides/${this.shopId}`, formData)
        }

        this.resolve(true)
        this.$refs.addForm.reset()
        this.dialog = false
      } catch (error) {}

      this.loading = false
    },
    cancel () {
      this.resolve(false)
      this.$refs.addForm.reset()
      this.dialog = false
    }
  }
}
</script>
