<template>
  <div>
  <AppBar :title="$t('editShoppingCenter.titleUAE')"/>
  <v-progress-linear v-if="loading" color="blue" class="progress" indeterminate></v-progress-linear>
  <v-card flat v-if="shop" class="rounded-xl pt-2 pb-4">
    <v-card-text>
      <v-form ref="addForm" v-model="valid">
        <v-row>
          <v-col cols="12" md="5">
            <h4>{{ $t('editShoppingCenter.shopData') }}</h4>
            <v-text-field v-model="shop.name" :label="$t('editShoppingCenter.name')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.company" :label="$t('editShoppingCenter.company')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.websiteUrl" :label="$t('editShoppingCenter.subdomain')" disabled></v-text-field>
            <v-text-field v-model="shop.subdomain" :label="$t('editShoppingCenter.siteAddress')" disabled></v-text-field>
            <v-text-field v-model="shop.email" :label="$t('editShoppingCenter.email')" :rules="emailRules"></v-text-field>
            <h4 class="mt-4">{{ $t('editShoppingCenter.contact') }}</h4>
            <div class="mb-5">
              <MazPhoneNumberInput v-model="phone" size="sm" show-code-on-list no-flags @update="phoneResult = $event" :rules="required" />
            </div>
            <v-text-field v-model="shop.contact.email" label="Email" :rules="emailRules"></v-text-field>
            <h4 class="mt-4">Social Media</h4>
            <v-text-field v-model="shop.socialMediaLinks.facebook" label="Facebook"></v-text-field>
            <v-text-field v-model="shop.socialMediaLinks.instagram" label="Instagram"></v-text-field>
            <v-text-field v-model="shop.socialMediaLinks.linkedIn" label="LinkedIn"></v-text-field>
            <v-text-field v-model="shop.socialMediaLinks.youTube" label="YouTube"></v-text-field>
            <h4 class="mt-4">{{$t('editShoppingCenter.homePage')}}</h4>
            <v-text-field v-model="shop.header.title" :label="$t('editShoppingCenter.header')" :rules="required"></v-text-field>
            <v-text-field v-model="shop.header.subtitle" :label="$t('editShoppingCenter.description')" :rules="required"></v-text-field>
          </v-col>
          <v-col cols="12" md="5" offset-md="1">
            <h4 class="mb-4">{{ $t('editShoppingCenter.previewGraphics') }}</h4>
            <h5>{{ $t('editShoppingCenter.logo') }}</h5>
              <img class="my-2" height="50" :src="url+shop.logo"/>
            <div v-if="shop.darkModeLogo">
              <h5>{{ $t('editShoppingCenter.darkModeLogo') }}</h5>
              <img class="my-2" height="50" :src="url+shop.darkModeLogo"/>
            </div>
            <div v-if="shop.giftCardImage">
              <h5>{{ $t('editShoppingCenter.giftCardImage') }}</h5>
              <img class="my-2" height="150" :src="url+shop.giftCardImage"/>
            </div>
            <h4 class="mt-4">{{ $t('editShoppingCenter.changeGraphics') }}</h4>
            <v-file-input v-model="logo" :label="$t('editShoppingCenter.logo')" prepend-icon="mdi-camera" color="secondary" accept="image/*"></v-file-input>
            <v-file-input v-model="darkModeLogo" :label="$t('editShoppingCenter.darkModeLogo')" prepend-icon="mdi-camera" color="secondary" accept="image/*"></v-file-input>
            <v-file-input v-model="giftCardImage" :label="$t('editShoppingCenter.giftCardImage')" prepend-icon="mdi-camera" color="secondary" accept="image/*"></v-file-input>
            <h4 class="mt-4 mb-1">{{$t('editShoppingCenter.slides')}}</h4>
            <v-row>
              <v-col
                v-for="slide in shop.carouselSlides"
                :key="slide.id"
                class="d-flex child-flex"
                cols="4"
              >
                <v-img
                  :src="url+slide.image"
                  aspect-ratio="1"
                  class="grey lighten-2"
                >
                <div class="ma-2 d-flex">
                  <v-btn fab x-small @click="updateSlide(slide.id)" rounded><v-icon>mdi-pencil</v-icon></v-btn>
                  <v-spacer></v-spacer>
                  <v-btn v-if="shop.carouselSlides.length > 1" fab x-small @click="removeSlide(slide.id)" rounded><v-icon>mdi-close</v-icon></v-btn>
                </div>
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
            <v-btn v-if="shop.carouselSlides.length < 5" depressed color="secondary" :loading="loading" :disabled="loading" class="mt-4" @click="addSlide" rounded>{{ $t('buttons.add') }}</v-btn>
          </v-col>
          <v-col cols="12" md="5">
            <h4>{{ $t('editShoppingCenter.colors') }}</h4>
            <v-row>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>Primary</h5>
                    <v-sheet dark :color="shop.colorPalette.primary" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.colorPalette.primary }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.colorPalette.primary" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>Secondary</h5>
                    <v-sheet dark :color="shop.colorPalette.secondary" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.colorPalette.secondary }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.colorPalette.secondary" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>background</h5>
                    <v-sheet dark :color="shop.colorPalette.background" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.colorPalette.background }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.colorPalette.background" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>Accent</h5>
                    <v-sheet dark :color="shop.colorPalette.accent" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.colorPalette.accent }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.colorPalette.accent" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>Error</h5>
                    <v-sheet dark :color="shop.colorPalette.error" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.colorPalette.error }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.colorPalette.error" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
              <v-col cols="6">
                <v-menu :close-on-content-click="false" max-width="300" offset-x>
                  <template v-slot:activator="{ on, attrs }">
                    <h5>Success</h5>
                    <v-sheet dark :color="shop.colorPalette.success" v-bind="attrs" v-on="on" class="pa-4">
                      <pre>{{ shop.colorPalette.success }}</pre>
                    </v-sheet>
                  </template>
                  <v-color-picker v-model="shop.colorPalette.success" hide-mode-switch mode="hexa" :rules="required"></v-color-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col cols="12" md="6">
          <v-btn depressed color="secondary" :loading="loading" :disabled="loading" @click="save" rounded> {{$t('editShoppingCenter.save')}} </v-btn>
          <v-btn depressed class="ml-4" @click="$router.push({ name: 'ShoppingCentersUAE' })" :disabled="loading" rounded> {{$t('editShoppingCenter.close')}} </v-btn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
    <Confirm ref="confirm"></Confirm>
    <AddSlide ref="add"></AddSlide>
  </div>
</template>
<script>
import Service from '@/services'
import Confirm from '@/components/dialogs/Confirm.vue'
import AddSlide from '@/components/dialogs/addSlide.vue'

export default {
  components: { Confirm, AddSlide },
  name: 'EditShoppingCenter',
  data () {
    return {
      menu: false,
      valid: true,
      loading: false,
      shop: null,
      logo: null,
      phone: null,
      darkModeLogo: null,
      giftCardImage: null,
      required: [(v) => !!v || this.$t('validation.fieldRequired')],
      emailRules: [
        v => !!v || this.$t('validation.emailRequired'),
        v => /.+@.+\..+/.test(v) || this.$t('validation.incorrectEmailAddress')
      ]
    }
  },
  created () {
    if (this.$route.params.id) {
      this.getShoppingCenter()
    }
  },
  computed: {
    url () {
      return process.env.VUE_APP_BASE + 'uploads/'
    }
  },
  methods: {
    async getSlides () {
      this.loading = true

      const { data } = await Service.get(`/CarouselSlides/${this.$route.params.id}`)
      this.shop.carouselSlides = data

      this.loading = false
    },
    async getShoppingCenter () {
      this.loading = true

      const { data } = await Service.get(`/DubaiShoppingCenters/${this.$route.params.id}/Management`)
      this.shop = data
      this.phone = '+' + this.shop.contact.phone.countryCallingCode + this.shop.contact.phone.number

      this.loading = false
    },
    async removeSlide (id) {
      this.loading = true

      if (await this.$refs.confirm.open(this.$t('editShoppingCenter.removeSlide'), this.$t('editShoppingCenter.removeSlideConfirm'))) {
        try {
          await Service.delete('CarouselSlides', id)
          await this.getSlides()
        } catch (error) {}
      }

      this.loading = false
    },
    async updateSlide (id) {
      this.loading = true

      if (await this.$refs.add.open(this.$t('editShoppingCenter.change'), id, this.$route.params.id)) {
        try {
          await this.getSlides()
        } catch (error) {}
      }

      this.loading = false
    },
    async addSlide () {
      this.loading = true

      if (await this.$refs.add.open(this.$t('editShoppingCenter.addNew'), null, this.$route.params.id)) {
        try {
          await this.getSlides()
        } catch (error) {}
      }

      this.loading = false
    },
    async save () {
      this.$refs.addForm.validate()

      if (!this.valid) return
      this.loading = true

      const formData = new FormData()
      formData.append('Data',
        JSON.stringify({
          Name: this.shop.name,
          Company: this.shop.company,
          Email: this.shop.email,
          Header: {
            Title: this.shop.header.title,
            Subtitle: this.shop.header.subtitle
          },
          Contact: {
            Email: this.shop.contact.email,
            Phone: {
              CountryCode: this.phoneResult.countryCode,
              Number: this.phoneResult.nationalNumber
            }
          },
          SocialMediaLinks: {
            Facebook: this.shop.socialMediaLinks.facebook,
            Instagram: this.shop.socialMediaLinks.instagram,
            LinkedIn: this.shop.socialMediaLinks.linkedIn,
            YouTube: this.shop.socialMediaLinks.youTube
          },
          ColorPalette: {
            Primary: this.shop.colorPalette.primary,
            Secondary: this.shop.colorPalette.secondary,
            Background: this.shop.colorPalette.background,
            Accent: this.shop.colorPalette.accent,
            Error: this.shop.colorPalette.error,
            Success: this.shop.colorPalette.success
          }
        })
      )

      if (this.logo) {
        formData.append('Logo', this.logo)
      }

      if (this.darkModeLogo) {
        formData.append('DarkModeLogo', this.darkModeLogo)
      }

      if (this.giftCardImage) {
        formData.append('GiftCardImage', this.giftCardImage)
      }

      if (this.carouselSlides) {
        for (let i = 0; i < this.carouselSlides.length; i++) {
          formData.append('CarouselSlides', this.carouselSlides[i])
        }
      }

      try {
        await Service.put(`/DubaiShoppingCenters/${this.shop.id}`, formData)

        this.$refs.addForm.reset()
        this.$router.push({ name: 'ShoppingCentersUAE' })
      } catch (error) {}

      this.loading = false
    }
  }
}
</script>
